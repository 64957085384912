<template>
  <div class="max-w-xs quizcard relative m-auto">
    <div class="h-20 left-2 bg-primary absolute rounded-md behind"></div>
    <div class="p-5 pt-7 relative z-10 rounded-md bg-white quiz">
      <h1 class="text-center font-semibold text-md">
        Behavioral Response & Engagement Test
      </h1>
      <p class="text-center py-3 opacity-60 text-xs">3 Quizzes</p>
      <p class="opacity-90">
        Behavioral assessment is a structured study and analysis of a person's
        behavior using various methods like interviews, direct supervision
      </p>
      <p class="text-center py-3 opacity-60 text-xs"><span></span>24hrs</p>
      <button @click="$emit('activatePopup')" class="w-4/5 btn m-auto">
        Take Quiz
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuizCard",
};
</script>

<style scoped>
.quiz {
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
    drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}

.behind {
  transform: matrix(0.99, 0.06, -0.12, 1, 0, 0);
  z-index: 1;
  width: 95%;
}
</style>