<template>
  <div class="home bg-slate">
    <the-navigation></the-navigation>

    <quiz-popup v-if="popupactive" @deactivate-popup="closePopup"></quiz-popup>

    <div class="max-w-screen-xl container py-10 m-auto">
      <div
        class="
          relative
          background
          rounded-large
          mt-20
          md:flex
          flex-row-reverse
          min-height
        "
      >
        <div class="image relative -top-20 md:-top-14 md:w-2/5 lg:-top-20">
          <img class="w-full" src="@/assets/Images/home/Welcome.png" alt="" />
        </div>
        <div
          class="
            relative
            -top-14
            md:top-0 md:w-3/5
            text-white
            px-3
            md:px-5 md:py-5
            lg:px-7 lg:-right-7
            m-auto
          "
        >
          <h1 class="text-lg font-light leading-11 lg:text-xl">
            <span class="font-hairline">Hello {{ name }}</span> <br /><span
              class="font-semibold text-secondary"
            >
              Let's Get Started</span
            >
          </h1>
          <p class="pt-5 lg:text-base lg:leading-7">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut at
            molestias praesentium magnam in ipsa, inventore alias debitis totam
            fugit doloribus, repellendus a nam dolores quasi minus porro cumque
            aliquam!
          </p>
        </div>
      </div>

      <div class="mt-24 h-96 flex flex-wrap justify-center items-start">
        <div class="w-full">
          <quiz-card @activate-popup="openPopup"></quiz-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuizCard from "../components/QuizCard.vue";
import TheNavigation from "../components/TheNavigation.vue";
import QuizPopup from "../components/QuizPopup.vue";
export default {
  components: { TheNavigation, QuizCard, QuizPopup },
  name: "Home",
  data() {
    return {
      popupactive: false,
      quizTaker: null,
      name: null,
    };
  },
  methods: {
    openPopup() {
      this.popupactive = true;
      document.body.style.overflow = "hidden";
    },
    closePopup() {
      this.popupactive = false;
      document.body.style.overflow = "auto";
    },
    getQuizTaker() {
      this.quizTaker = this.$store.state.user.quizTaker;
      this.name = this.quizTaker.name;
    },
  },
  mounted() {
    this.getQuizTaker();
  },
};
</script>

<style scoped>
@media screen and (min-width: 976px) {
  .min-height {
    min-height: 20rem;
  }
}
</style>