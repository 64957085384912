<template>
  <div class="popup">
    <div
      @click="$emit('deactivatePopup')"
      class="
        z-20
        overlay
        fixed
        top-0
        bottom-0
        right-0
        left-0
        bg-opacity-20
        backdrop-blur-md
        h-full
        bg-black
      "
    ></div>
    <div
      class="w-full flex absolute justify-center top-0 bottom-0 items-center"
    >
      <div
        class="
          popup-content
          fixed
          z-40
          rounded-large
          lg:w-4/5
          w-11/12
          bg-white
          overflow-auto
          px-4
          py-7
          md:px-10
        "
      >
        <div class="lg:flex items-start lg:gap-4 justify-center">
          <div class="lg:w-1/2 max-w-lg">
            <h1 class="text-md font-bold text-tetiary lg:text-lg font-headers">
              Behavioral Response
              <span class="text-secondary"> & Engagement Test</span>
            </h1>

            <div class="flex gap-1 mt-8 lg:mt-12 max-w-md">
              <div
                class="
                  text-center
                  w-2/6
                  bg-slate
                  flex flex-col
                  small-box
                  p-4
                  justify-center
                  items-center
                "
              >
                <p class="mb-2"><img src="@/assets/Images/home/time.png" /></p>
                <p class="font-semibold">Time</p>
                <p>24 Hours</p>
              </div>
              <!-- <div
                class="
                  text-center
                  w-2/6
                  bg-slate
                  flex flex-col
                  p-4
                  justify-center
                  items-center
                  small-box
                "
              >
                <p class="mb-2">
                  <img src="@/assets/Images/home/target.png" />
                </p>
                <p class="font-semibold">&nbsp;</p>
                <p>&nbsp;</p>
              </div> -->
              <div
                class="
                  text-center
                  w-2/6
                  bg-slate
                  flex flex-col
                  p-4
                  justify-center
                  items-center
                  small-box
                "
              >
                <p class="mb-2">
                  <img src="@/assets/Images/home/user-id.png" />
                </p>
                <p class="font-semibold">Audience</p>
                <p>English</p>
              </div>
            </div>
          </div>

          <div class="mt-10 lg:m-0 lg:w-1/2 max-w-xl">
            <h1 class="text-base text-tetiary font-semibold">Description</h1>
            <p class="my-5">
              The Behavioral Response & Engagement Test identifies your key and
              pre-dominant drivers as enunciated by the 4 Drive Theory of
              Motivation. This is indicative of your Natural or Instinctive
              behavior & response.
            </p>
            <p class="my-5">
              It identifies your change and adaptation response (your
              conditioned behaviour) basis the four response styles. This is
              indicative of Conditioned Behavior
            </p>
            <p>
              It also identifies your communication style based on 4
              communication styles. This indicative of your learnt/taught
              behaviour
            </p>
          </div>
        </div>

        <div class="button w-full max-w-lg">
          <router-link
            @click="closepopup"
            :to="{ name: 'QuizOne' }"
            class="bg-tetiary p-4 mt-8 w-3/4 lg:w-60 btn m-auto lg:mx-0"
            >Start</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuizPopup",
  methods: {
    closepopup() {
      document.body.style.overflow = "auto";
    },
  },
};
</script>

<style scoped>
.overlay {
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.small-box {
  aspect-ratio: 1/1;
}

.popup-content {
  max-width: 1350px;
  height: min(750px, 93%);
  z-index: 4000;
}

@media screen and (min-width: 976px) {
  .button {
    max-width: 1104px;
    padding-left: 10px;
  }
}

@media screen and (min-width: 576px) {
  .popup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>